module.exports = {
  siteTitle: 'Rany ElHousieny', // <title>
  manifestName: 'Rany Website',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/rany-elhousieny/`, // This path is subpath of your hosting https://domain/portfolio
  firstName: 'Rany',
  lastName: 'ElHousieny',
  contentType: 'info',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/ranyelhousieny/',
    },

    {
      icon: 'fa-medium',
      name: 'Medium',
      url: 'https://ranyel.medium.com/',
    },
    {
      icon: 'fa-youtube',
      name: 'YouTube',
      url: 'https://www.youtube.com/channel/UCJ6bFYSkBws-ug6fqJ_9CNg',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/ranyelhousieny',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/ranyelhousieny',
    },

    {
      icon: 'fa-google',
      name: 'Publications',
      url: 'https://rany.elhousieny.com/publications',
    },
  ],
  // social
  publications: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url:
        'https://www.linkedin.com/in/ranyelhousieny/detail/recent-activity/posts/',
    },

    {
      icon: 'fa-medium',
      name: 'Medium',
      url: 'https://ranyel.medium.com/',
    },
    {
      icon: 'fa-youtube',
      name: 'YouTube',
      url: 'https://www.youtube.com/channel/UCJ6bFYSkBws-ug6fqJ_9CNg',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/ranyelhousieny',
    },
    {
      icon: 'fa-google',
      name: 'Publications',
      url: 'https://rany.elhousieny.com/publications',
    },
  ],
  microfrontends: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url:
        'https://www.linkedin.com/pulse/micro-frontends-from-begining-expert-rany-elhousieny-phd%E1%B4%AC%E1%B4%AE%E1%B4%B0/',
    },

    {
      icon: 'fa-medium',
      name: 'Medium',
      url:
        'https://javascript.plainenglish.io/micro-frontends-from-the-begining-to-expert-33d660a05bb5',
    },
    {
      icon: 'fa-youtube',
      name: 'YouTube',
      url:
        'https://www.youtube.com/playlist?list=PLjzEd-em7iW_-cUUki8Zt15mGF6ZrOQPn',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/ranyelhousieny/react-microfrontends',
    },
    {
      icon: 'fa-google',
      name: 'Publications',
      url: 'https://rany.elhousieny.com/publications',
    },
  ],
  email: 'ranyel@msn.com',
  phone: '(206) 569-8844',
  address: 'Bothell, WA 98011',
};
